import { useEffect, useState } from 'react';
import { ProgressBar, Skeleton } from '..';
import styles from './EmailLoader.module.scss';

interface ProgressLoadingStep {
  progress: number;
  title: string;
}

interface Props {
  steps: ProgressLoadingStep[];
  title?: string;
}
export function EmailLoader({ steps, title = "Hang tight, we're writing your email..." }: Props) {
  const [currentProgressLoadingStepIndex, setCurrentProgressLoadingStepIndex] = useState(0);
  const step = steps[currentProgressLoadingStepIndex];

  // this provides the illusion of progress. to not make it look so fake, i've increased the loading time by one second with every step
  useEffect(() => {
    if (currentProgressLoadingStepIndex === steps.length - 1) return;
    const duration = 1000 * (currentProgressLoadingStepIndex + 1);
    const handle = setInterval(() => {
      setCurrentProgressLoadingStepIndex(i => i + 1);
    }, duration);
    return () => clearInterval(handle);
  }, [currentProgressLoadingStepIndex, steps]);

  return (
    <div className={styles.Container} data-testid="EmailLoader">
      <div className={styles.Header}>
        <Skeleton avatarSize="sm" variant="avatar" />
        <div className={styles.HeaderContent}>
          <Skeleton textWidthPercent={50} />
          <Skeleton textWidthPercent={25} />
        </div>
      </div>
      <div className={styles.BodyContainer}>
        <div className={styles.BodyTitle} data-testid="EmailLoader__title">
          {title}
        </div>
        <div className={styles.ProgressContainer}>
          <div className={styles.ProgressTitle} data-testid="EmailLoader__step">
            {step.title}
          </div>
          <ProgressBar current={step.progress} total={100} width="320px" />
        </div>
      </div>
    </div>
  );
}
