import { Skeleton as Skeleton6ds } from '@saleswhale/barnacle/components';
import {
  SkeletonVariant,
  TextSkeletonSize,
  AvatarSkeletonSize,
} from '@saleswhale/barnacle/components/Skeleton/constant';
import styles from './Skeleton.module.scss';

interface Props {
  animate?: boolean;
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg' | 'l' | '2xl';
  className?: string;
  full?: boolean;
  style?: React.CSSProperties;
  testId?: string;
  textSize?: 'body' | 'header';
  textWidthPercent?: 25 | 50 | 75 | 100;
  variant?: 'rectangular' | 'text' | 'avatar';
}

export function Skeleton({
  className = '',
  variant = 'text',
  textSize = 'body',
  textWidthPercent = 100,
  avatarSize,
  animate = true,
  style,
  full,
  testId = 'Skeleton',
}: Props) {
  return (
    <div className={`${className} ${full && styles.Full}`} data-testid={testId}>
      <Skeleton6ds
        animate={animate}
        avatarSize={avatarSize as AvatarSkeletonSize}
        style={style}
        textSize={textSize as TextSkeletonSize}
        textWidthPercent={textWidthPercent}
        variant={variant as SkeletonVariant}
      />
    </div>
  );
}
