import { Chip } from '@saleswhale/barnacle/components';
import styles from './ChipMultiSelect.module.scss';

interface Props {
  value: string;
  onDismiss?: () => void;
}

export function ChipMultiSelect({ onDismiss, value }: Props) {
  return (
    <div
      className={`${!!onDismiss ? styles.Container__active : styles.Container} chip`}
      data-testid="ChipMultiSelect"
    >
      <Chip onDismiss={onDismiss}>{value}</Chip>
    </div>
  );
}
