import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';
import { didError } from 'app/components/Elements';
import { isAbortError } from 'utils/errorUtils';

// you can view the defaults here: https://tanstack.com/query/v4/docs/guides/important-defaults
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: window.isPlaywright ? 0 : 3, // we don't want to retry failed requests in Playwright tests
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { queryKey } = query;
      // we don't want to show a toast if it's an abort error
      if (isAbortError(error)) return;
      // we don't want to show a toast for auth errors as well as ai block preview errors
      if (
        !queryKey.includes('me') &&
        !(queryKey.includes('ai_blocks') && queryKey.includes('preview'))
      )
        didError(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      if (isAbortError(error)) return;
      if (!mutation.options.onError) didError(error);
    },
  }),
});
