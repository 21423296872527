import { HelpCircle } from '@saleswhale/barnacle/icons';
import { ReactNode } from 'react';
import { Popup } from '../../Popup';
import styles from './AttributeItem.module.scss';

interface AttributeItemProps {
  header: ReactNode;
  body?: string | number | null | ReactNode;
  className?: string;
  testId?: string;
  tooltip?: string;
  tooltipTestId?: string;
}

export default function AttributeItem({
  header,
  body,
  tooltip,
  tooltipTestId = 'AttributeItem__popup',
  testId = 'AttributeItem',
  className = '',
}: AttributeItemProps) {
  return (
    <div className={`${styles.ItemContainer} ${className}`} data-testid={testId}>
      <div className={styles.Header}>
        <span>{header}</span>
        {tooltip && (
          <Popup
            content={tooltip}
            data-testid={tooltipTestId}
            position="bottom right"
            trigger={
              <HelpCircle className={styles.PopupIcon} data-testid={`${testId}Icon`} size={13} />
            }
          />
        )}
      </div>
      <div className={styles.Body} data-testid={`${testId}__body`}>
        <span>{body || '–'}</span>
      </div>
    </div>
  );
}
