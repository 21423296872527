import { ReactComponent as AvatarIcon } from '../../../../assets/icons/avatar.svg';
import { getClass } from '../../../../utils/getClass';
import './Avatar.scss';

interface AvatarProps {
  userName: string | undefined;
  className?: string;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
}

const colors = ['#99def7', '#fecdca', '#fef7c3', '#a6f4c5', '#ddd6fe'];

const hashCode = (string: string) => {
  let hash = 0;

  if (string) {
    for (let i = 0; i < string.length; i++) {
      const char = string.charCodeAt(i);

      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
  }

  return hash & hash;
};

const colorIndex = (userName: string, colorLength: number) => {
  const code = hashCode(userName);

  return Math.abs(Math.floor(code % colorLength));
};

const initials = (arr: any) => {
  if (arr) {
    switch (arr.length) {
      case 0:
        return '';
      case 1:
        return arr[0].charAt(0).toUpperCase();
      default:
        return arr[0].charAt(0).toUpperCase() + arr[arr.length - 1].charAt(0).toUpperCase();
    }
  }
  return <AvatarIcon />;
};

export const Avatar = ({ userName, size = 'medium', className }: AvatarProps) => {
  const arr = userName && userName.trim().split(' ');
  const backgroundColor = userName && colors[colorIndex(userName, colors.length)];

  const style = {
    backgroundColor,
  };

  return (
    <div
      className={`${getClass('Avatar', { size })} ${className ? className : ''}`}
      data-testid="Avatar"
      style={style}
    >
      {initials(arr)}
    </div>
  );
};
