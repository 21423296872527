import { TextArea } from '@saleswhale/barnacle/components';
import styles from './TextArea6ds.module.scss';

interface Props {
  onChange: (value: string) => void;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  hint?: string;
  infoText?: string;
  label?: string;
  mark?: string;
  placeholder?: string;
  testId?: string;
  textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  value?: string;
}
export function TextArea6ds({
  label,
  className = '',
  textAreaProps,
  testId = 'TextArea6ds',
  error,
  disabled,
  ...props
}: Props) {
  return (
    <TextArea.Labeled
      className={`${styles.Container} ${error ? styles['Container--error'] : ''} ${
        disabled ? styles['Container--disabled'] : ''
      } ${className}`}
      data-testid={testId}
      label={label}
      textAreaProps={textAreaProps}
      {...props}
    />
  );
}
