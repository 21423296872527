import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { FLAGSMITH_API_KEY } from './config/environment';
import { initializeMixpanel } from './initializers/analytics';
import initializePendo from './initializers/pendo';
import { queryClient } from './services/reactQuery';
import { rollbarConfig } from './services/rollbar';
import './index.scss';

initializeMixpanel();
initializePendo();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <RollbarProvider config={rollbarConfig}>
      <FlagsmithProvider
        flagsmith={flagsmith}
        options={{
          environmentID: FLAGSMITH_API_KEY,
          cacheFlags: false,
          api: 'https://api.flagsmith.com/api/v1/',
        }}
      >
        <ErrorBoundary>
          <Router>
            <App />
          </Router>
        </ErrorBoundary>
      </FlagsmithProvider>
    </RollbarProvider>
    <ReactQueryDevtools position="bottom-right" />
  </QueryClientProvider>
);
