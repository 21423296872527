import { ReactElement, ReactNode, SyntheticEvent } from 'react';
import {
  Dropdown as SemanticDropdown,
  DropdownItemProps,
  DropdownProps,
  LabelProps,
} from 'semantic-ui-react';
import { Icon } from '..';
import './Dropdown.scss';
// eslint-disable-next-line import/order
import styles from './Dropdown.module.scss';

interface Props {
  options:
    | {
        key: any;
        text: string;
        value: any;
        content?: ReactNode;
        description?: string;
        disabled?: boolean;
      }[]
    | DropdownItemProps[];
  additionLabel?: string | ReactNode;
  allowAdditions?: boolean;
  className?: string;
  clearable?: boolean;
  defaultOpen?: boolean;
  defaultValue?: string;
  direction?: 'left' | 'right';
  disabled?: boolean;
  dropdownClass?: string;
  error?: boolean;
  errorMsg?: string;
  fluid?: boolean;
  icon?: ReactElement | string | null;
  inline?: boolean;
  label?: string;
  multiple?: boolean;
  name?: string;
  noResultsMessage?: ReactNode;
  onAddItem?: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  onBlur?: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  onChange?: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onClick?: (event: SyntheticEvent<HTMLElement>) => void;
  //You'll want to extract {value} from data
  onClose?: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onOpen?: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onSearchChange?: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  openOnFocus?: boolean;
  placeholder?: string;
  renderLabel?: (item: DropdownItemProps, index: number, defaultLabelProps: LabelProps) => any;
  scrolling?: boolean;
  search?: boolean | ((options: DropdownItemProps[], value: string) => any);
  searchQuery?: string;
  selected?: string;
  selection?: boolean;
  testId?: string;
  text?: string;
  trigger?: ReactElement;
  upward?: boolean;
  value?: DropdownProps['value'];
}

export function Dropdown({
  className = '',
  label,
  error,
  errorMsg,
  dropdownClass = '',
  upward,
  testId = 'Dropdown',
  defaultOpen = false,
  disabled = false,
  renderLabel,
  ...semanticDropdownProps
}: Props) {
  return (
    <div className={`${styles.Dropdown} ${className}`} data-testid={testId}>
      {label && (
        <label className={styles.Label} data-testid="Dropdown__label">
          {label}
        </label>
      )}
      <SemanticDropdown
        className={`${styles.Dropdown} ${dropdownClass}`}
        data-testid="Dropdown__dropdown"
        defaultOpen={defaultOpen}
        disabled={disabled}
        error={error}
        icon={
          <Icon
            className="dropdown icon custom"
            name="ChevronDown"
            testId="Dropdown__defaultIcon"
          />
        }
        renderLabel={renderLabel}
        selectOnBlur={false}
        upward={upward}
        {...semanticDropdownProps}
        scrolling
      />
      {error && errorMsg && (
        <p className={styles.ErrorMessage} data-testid="Dropdown__error">
          {errorMsg}
        </p>
      )}
    </div>
  );
}
