// TODO: undisable this after adding in other footer elements
/* eslint-disable unused-imports/no-unused-vars */
import MdIcon from '@saleswhale/barnacle/components/Icon';
import { isNull } from 'lodash';
import { MetaSchema } from '../../../schemas';
import styles from './TableFooter.module.scss';

//TODO: Remove unused props
interface Props {
  colspan: number;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  meta: MetaSchema | null;
  className?: string;
  isLoading?: boolean;
  itemsPerPage?: number;
  name?: string;
  testId?: string;
}

export const TableFooter = ({
  className = '',
  colspan,
  goToNextPage,
  goToPrevPage,
  meta,
  testId = 'TableFooter',
  isLoading,
}: Props) => {
  return (
    <table className={`${className} ${styles.Container}`} data-testid={testId}>
      <tfoot>
        <tr>
          <td colSpan={colspan}>
            {meta && (
              <div className={styles.Pagination} data-testid="TableFooter__count">
                <div>
                  {!isNull(meta.totalPages) && !isLoading && (
                    <span data-testid="TableFooter__currentAndTotalPages">
                      {/* TODO: edit and jump to page using Input */}
                      Page {meta.currentPage} of {meta.totalPages}
                    </span>
                  )}
                </div>
                <div className={styles.PageControls}>
                  <div
                    className={`${styles.PageControlPrev} ${
                      meta.prevPage ? '' : styles.PageControlPrev__disabled
                    }`}
                    data-testid="TableFooter__prevButton"
                    onClick={goToPrevPage}
                  >
                    <MdIcon className={styles.PageControlIcon} type="MdWest" />
                    <span>Previous</span>
                  </div>
                  <div className={styles.PageNumber} data-testid="TableFooter__page">
                    <span>{meta.currentPage}</span>
                  </div>
                  <div
                    className={`${styles.PageControlNext} ${
                      meta.nextPage ? '' : styles.PageControlNext__disabled
                    }`}
                    data-testid="TableFooter__nextButton"
                    onClick={goToNextPage}
                  >
                    <span>Next</span>
                    <MdIcon className={styles.PageControlIcon} type="MdEast" />
                  </div>
                </div>
                <div>
                  <span>{/* TODO: display/edit number of rows */}</span>
                </div>
              </div>
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
