import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import { Icon } from '../Icon';
import BreadcrumbItem from './BreadcrumbItem';
import styles from './Breadcrumb.module.scss';

export type BreadcrumbProps = {
  children: ReactJSXElement | ReactJSXElement[];
};

export const Breadcrumb = ({ children }: BreadcrumbProps) => {
  const crumbs = React.Children.map(children, (child, currentIndex) => (
    <>
      {currentIndex !== 0 && <Icon name="Slash" right={14} size={20} />}
      {child}
    </>
  ));

  return (
    <div className={styles.BreadcrumbContainer} data-testid="Breadcrumb">
      {crumbs}
    </div>
  );
};

Breadcrumb.Item = BreadcrumbItem;
