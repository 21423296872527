import { Dropdown as Dropdown6DS } from '@saleswhale/barnacle/components';
import { ChevronDown, ChevronUp, DotsVertical } from '@saleswhale/barnacle/icons';
import { ReactNode, useState } from 'react';
import { Button } from '../Button';
import './ButtonDropdown.scss';

interface ButtonDropdownProps {
  children: ReactNode;
  label: string;
  buttonClassName?: string;
  className?: string;
  destructive?: boolean;
  disabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  size?: 's' | 'm' | 'l';
  testId?: string;
  theme?: 'primary' | 'secondary' | 'flush';
}

interface EllipsisDropdownProps {
  children: ReactNode;
  className?: string;
  testId?: string;
}

export function ButtonDropdown({
  children,
  className = '',
  destructive,
  disabled,
  label,
  theme = 'secondary',
  size,
  testId = 'ButtonDropdown',
  buttonClassName = '',
  onClick,
}: ButtonDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = (visible: boolean) => {
    setIsOpen(visible);
  };

  const menu = (
    <Dropdown6DS.Menu
      className="DropdownMenu"
      data-testid={`${testId}__menu`}
      onBlur={() => toggleOpen(false)}
    >
      {children}
    </Dropdown6DS.Menu>
  );

  return (
    <Dropdown6DS menu={menu} onVisibleChange={toggleOpen} overlayClassName={className}>
      <Button
        className={buttonClassName}
        destructive={destructive}
        disabled={disabled}
        icon={isOpen ? <ChevronUp /> : <ChevronDown />}
        iconDirection="right"
        label={label}
        onClick={onClick}
        size={size}
        testId={testId}
        theme={theme}
      />
    </Dropdown6DS>
  );
}

export function EllipsisDropdown({
  children,
  className = '',
  testId = 'EllipsisDropdown',
}: EllipsisDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const menu = (
    <Dropdown6DS.Menu className="DropdownMenu" data-testid={`${testId}__menu`}>
      {children}
    </Dropdown6DS.Menu>
  );

  return (
    <Dropdown6DS menu={menu} overlayClassName={className}>
      <Button
        className="EllipsisDropdown__button"
        onClick={toggleOpen}
        testId={testId}
        theme="flush"
      >
        <DotsVertical size={16} />
      </Button>
    </Dropdown6DS>
  );
}
