import { ReactComponent as AddIconDark } from './add-icon-dark.svg';
import { ReactComponent as AddIconGrey } from './add-icon-grey.svg';
import { ReactComponent as AddIconWhite } from './add-icon-white.svg';
import { ReactComponent as AddMessage } from './add-message.svg';
import { ReactComponent as AddPerson } from './add-person.svg';
import { ReactComponent as AddressCard } from './address-card.svg';
import { ReactComponent as AiDefaultActionIcon } from './ai-default-action-icon.svg';
import { ReactComponent as AiIconExtendedBlue } from './ai-icon-extended-blue.svg';
import { ReactComponent as AlertCritical } from './alert-critical.svg';
import { ReactComponent as AlertErrorRed } from './alert-error-red.svg';
import { ReactComponent as AlertError } from './alert-error.svg';
import { ReactComponent as AlertInfo } from './alert-info.svg';
import { ReactComponent as AlertSuccess } from './alert-success.svg';
import { ReactComponent as AlertWarning } from './alert-warning.svg';
import { ReactComponent as Archive } from './archive.svg';
import { ReactComponent as ArrowDownBlue } from './arrow-down-blue.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowForward } from './arrow-forward.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowSide } from './arrow-side.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as Automation } from './automation.svg';
import { ReactComponent as Avatar } from './avatar.svg';
import { ReactComponent as Blockquote } from './blockquote.svg';
import { ReactComponent as Bold } from './bold.svg';
import { ReactComponent as BookOpen } from './book-open.svg';
import { ReactComponent as Bot } from './bot.svg';
import { ReactComponent as Briefcase } from './briefcase.svg';
import { ReactComponent as BroadcastBlue } from './broadcast-blue.svg';
import { ReactComponent as BroadCastGreen } from './broadcast-green.svg';
import { ReactComponent as BroadCastGrey } from './broadcast-grey.svg';
import { ReactComponent as BroadcastOrange } from './broadcast-orange.svg';
import { ReactComponent as BroadCastRed } from './broadcast-red.svg';
import { ReactComponent as CalendarLinkIcon } from './calendar-link-icon.svg';
import { ReactComponent as Campaigns } from './campaigns.svg';
import { ReactComponent as CcSalesRepIcon } from './cc-sales-rep-icon.svg';
import { ReactComponent as CenterToFitIcon } from './center-to-fit-icon.svg';
import { ReactComponent as ChatBot } from './chat-bot.svg';
import { ReactComponent as Chat } from './chat.svg';
import { ReactComponent as CheckDefault } from './check-default.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as ChevronDown } from './chevron-down.svg';
import { ReactComponent as ChevronRightLightBlue } from './chevron-right-light-blue.svg';
import { ReactComponent as ChevronRight } from './chevron-right.svg';
import { ReactComponent as ChevronUp } from './chevron-up.svg';
import { ReactComponent as CloseBold } from './close-bold.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as CoffeeMugIcon } from './coffee-mug-icon.svg';
import { ReactComponent as Connect } from './connect.svg';
import { ReactComponent as ConversationFlowStep } from './conversation-flow-step.svg';
import { ReactComponent as Conversation } from './conversation.svg';
import { ReactComponent as Conversations } from './conversations.svg';
import { ReactComponent as CreateTopicStepIcon } from './create-topic-step-icon.svg';
import { ReactComponent as CsvFileIcon } from './csv-file-icon.svg';
import { ReactComponent as CustomView } from './custom-view.svg';
import { ReactComponent as DefaultBotAvatar } from './default-bot-avatar.svg';
import { ReactComponent as DeleteSmall } from './delete-small.svg';
import { ReactComponent as DeleteTrashIcon } from './delete-trash-icon.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as DotLoaderBouncy } from './dot-loader-bouncy.svg';
import { ReactComponent as DotLoader } from './dot-loader.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as DynamicContent } from './dynamic-content-icon.svg';
import { ReactComponent as EditButtonIcon } from './edit-button-icon.svg';
import { ReactComponent as EditEmailIcon } from './edit-email-icon.svg';
import { ReactComponent as EditPencil } from './edit-pencil.svg';
import { ReactComponent as EditTransparent } from './edit-transparent.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Ellipsis } from './ellipsis.svg';
import { ReactComponent as EmailDefaultIcon } from './email-default-icon.svg';
import { ReactComponent as EmailDivider } from './email-divider.svg';
import { ReactComponent as EnrollLeadsStep } from './enroll-leads-step-icon.svg';
import { ReactComponent as Enrollment } from './enrollment.svg';
import { ReactComponent as Envelope } from './envelope.svg';
import { ReactComponent as ErrorLine } from './error-line.svg';
import { ReactComponent as Excel } from './excel.svg';
import { ReactComponent as ExpandText } from './expand-text.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as FileAdd } from './file-add.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Flag } from './flag.svg';
import { ReactComponent as FolderOpenIcon } from './folder-open-icon.svg';
import { ReactComponent as GearTransparent } from './gear-transparent.svg';
import { ReactComponent as Gear } from './gear.svg';
import { ReactComponent as GreenLock } from './green-lock.svg';
import { ReactComponent as Heading } from './heading.svg';
import { ReactComponent as IntegrationHubspot } from './hubspot.svg';
import { ReactComponent as IcAlert } from './ic-alert.svg';
import { ReactComponent as IcTick } from './ic-tick.svg';
import { ReactComponent as IconAiAssistant } from './icon-ai-assistant.svg';
import { ReactComponent as IconBot } from './icon-bot.svg';
import { ReactComponent as BriefLibrary } from './icon-brief-library.svg';
import { ReactComponent as IconClouds } from './icon-clouds.svg';
import { ReactComponent as DragHandle } from './icon-drag-handle.svg';
import { ReactComponent as IconElectric } from './icon-electric.svg';
import { ReactComponent as IconEmail } from './icon-email.svg';
import { ReactComponent as EnterKey } from './icon-enter-key.svg';
import { ReactComponent as EscKey } from './icon-esc-key.svg';
import { ReactComponent as CsvFile } from './icon-file-csv.svg';
import { ReactComponent as DocFile } from './icon-file-doc.svg';
import { ReactComponent as FileFile } from './icon-file-file.svg';
import { ReactComponent as GifFile } from './icon-file-gif.svg';
import { ReactComponent as JpgFile } from './icon-file-jpg.svg';
import { ReactComponent as KeyFile } from './icon-file-key.svg';
import { ReactComponent as MdbFile } from './icon-file-mdb.svg';
import { ReactComponent as NumFile } from './icon-file-num.svg';
import { ReactComponent as PageFile } from './icon-file-page.svg';
import { ReactComponent as PdfFile } from './icon-file-pdf.svg';
import { ReactComponent as PngFile } from './icon-file-png.svg';
import { ReactComponent as PptFile } from './icon-file-ppt.svg';
import { ReactComponent as SvgFile } from './icon-file-svg.svg';
import { ReactComponent as TxtFile } from './icon-file-txt.svg';
import { ReactComponent as XlsFile } from './icon-file-xls.svg';
import { ReactComponent as ZipFile } from './icon-file-zip.svg';
import { ReactComponent as IconGmail } from './icon-gmail.svg';
import { ReactComponent as IconHide } from './icon-hide.svg';
import { ReactComponent as IconIntegrationSalesforce } from './icon-integration-salesforce.svg';
import { ReactComponent as IconLead } from './icon-lead.svg';
import { ReactComponent as Lightning } from './icon-lightning.svg';
import { ReactComponent as IconOutlook } from './icon-outlook.svg';
import { ReactComponent as RKey } from './icon-r-key.svg';
import { ReactComponent as IconReply } from './icon-reply.svg';
import { ReactComponent as IconSchedule } from './icon-scheduled.svg';
import { ReactComponent as IconTooltipInfo } from './icon-tooltip-info.svg';
import { ReactComponent as IconVideo } from './icon-video.svg';
import { ReactComponent as IconWarning } from './icon-warning.svg';
import { ReactComponent as IconZoomIn } from './icon-zoom-in.svg';
import { ReactComponent as IconZoomOut } from './icon-zoom-out.svg';
import { ReactComponent as IconPen } from './icon_pen.svg';
import { ReactComponent as ImageOutline } from './image-outline.svg';
import { ReactComponent as Image } from './image.svg';
import { ReactComponent as Indent } from './indent.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as InputError } from './input-error.svg';
import { ReactComponent as Insights } from './insights.svg';
import { ReactComponent as InstallPackage } from './install-package.svg';
import { ReactComponent as IntegrationDescription } from './integration-description-icon.svg';
import { ReactComponent as IntegrationInstallPackage } from './integration-install-package.svg';
import { ReactComponent as IntegrationSalesforce } from './integration-salesforce.svg';
import { ReactComponent as Integrations } from './integrations.svg';
import { ReactComponent as IntroductionEmailIcon } from './introduction-email-icon.svg';
import { ReactComponent as Italic } from './italic.svg';
import { ReactComponent as KeyLeft } from './key-left.svg';
import { ReactComponent as KeyRight } from './key-right.svg';
import { ReactComponent as LeadIconYellow } from './lead-icon-yellow.svg';
import { ReactComponent as LeadOwnerStep } from './lead-owner-step.svg';
import { ReactComponent as Lead } from './lead.svg';
import { ReactComponent as Leads } from './leads.svg';
import { ReactComponent as LightningGradient } from './lightning-gradient.svg';
import { ReactComponent as LinkBroken } from './link-broken.svg';
import { ReactComponent as LinkDark } from './link-dark.svg';
import { ReactComponent as LinkIcon } from './link-icon.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as MailOff } from './mail-off.svg';
import { ReactComponent as Map } from './map.svg';
import { ReactComponent as MergeTagIcon } from './merge-tag-icon.svg';
import { ReactComponent as MergeTag } from './merge-tag.svg';
import { ReactComponent as MessageClose } from './message-close.svg';
import { ReactComponent as MessageText } from './message-text.svg';
import { ReactComponent as NoData } from './no-data.svg';
import { ReactComponent as NoInvites } from './no-invites.svg';
import { ReactComponent as NoTasks } from './no-tasks.svg';
import { ReactComponent as NotInterestedEmailIcon } from './not-interested-email-icon.svg';
import { ReactComponent as Notes } from './notes.svg';
import { ReactComponent as OrderedList } from './ordered-list.svg';
import { ReactComponent as Outdent } from './outdent.svg';
import { ReactComponent as Overflow } from './overflow.svg';
import { ReactComponent as PaperClipIcon } from './paper-clip-icon.svg';
import { ReactComponent as Paper } from './paper.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as PictureIcon } from './picture-icon.svg';
import { ReactComponent as PieChartIcon } from './pie-chart-icon.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as NotificationIcon } from './pulsating-circle-icon.svg';
import { ReactComponent as QualifiedEmailIcon } from './qualified-email-icon.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as RedLock } from './red-lock.svg';
import { ReactComponent as Redo } from './redo.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as ReplyIconYellow } from './reply-icon-yellow.svg';
import { ReactComponent as ReturnDownRight } from './return-down-right.svg';
import { ReactComponent as ReturnRight } from './return-right.svg';
import { ReactComponent as ReviewCheckIcon } from './review-check-icon.svg';
import { ReactComponent as ReviewEllipsisIcon } from './review-ellipsis-icon.svg';
import { ReactComponent as ReviewErrorIcon } from './review-error-icon.svg';
import { ReactComponent as Review } from './review.svg';
import { ReactComponent as RockHandIcon } from './rock-hand-icon.svg';
import { ReactComponent as Rocket } from './rocket.svg';
import { ReactComponent as SalesGroupIcon } from './sales-group-icon.svg';
import { ReactComponent as SalesRepsList } from './sales-rep-list-icon.svg';
import { ReactComponent as SampleContactBadge } from './sample-contact-badge.svg';
import { ReactComponent as ScheduleCampaignStepIcon } from './schedule-campaign-step-icon.svg';
import { ReactComponent as ScheduleStep } from './schedule-step.svg';
import { ReactComponent as SearchIconBlack } from './search-icon-black.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as AiInboxStepIcon } from './settings-assistant-inbox-icon.svg';
import { ReactComponent as AiBccPreferenceStepIcon } from './settings-bcc-preferences-icon.svg';
import { ReactComponent as AiPersonaliseAssistantStepIcon } from './settings-personalize-assistant-icon.svg';
import { ReactComponent as AiSetWorkingHoursStepIcon } from './settings-set-working-hours-icon.svg';
import { ReactComponent as ShortenText } from './shorten-text.svg';
import { ReactComponent as SixSenseLogoWhite } from './six-sense-logo-white.svg';
import { ReactComponent as SixSenseLogo } from './six-sense-logo.svg';
import { ReactComponent as Slash } from './slash.svg';
import { ReactComponent as Sort } from './sort.svg';
import { ReactComponent as SplitTestIcon } from './split-test-icon.svg';
import { ReactComponent as Stack } from './stack.svg';
import { ReactComponent as StarClusterGradient } from './star-cluster-gradient.svg';
import { ReactComponent as Strikethrough } from './strikethrough.svg';
import { ReactComponent as Success } from './success.svg';
import { ReactComponent as SuppressionListsStep } from './suppression-lists-step.svg';
import { ReactComponent as SwitchHorizontal } from './switch-horizontal.svg';
import { ReactComponent as Tasks } from './tasks.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as ToastCritical } from './toast-critical-icon.svg';
import { ReactComponent as TrashBin } from './trash-bin.svg';
import { ReactComponent as TrashIconNew } from './trash-icon-new.svg';
import { ReactComponent as Triangle } from './triangle.svg';
import { ReactComponent as Underline } from './underline.svg';
import { ReactComponent as Undo } from './undo.svg';
import { ReactComponent as UnorderedList } from './unordered-list.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as VLeft } from './v-left.svg';
import { ReactComponent as VRight } from './v-right.svg';
import { ReactComponent as Video } from './video.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Zapier } from './zapier.svg';

const icons = {
  avatar: Avatar,
  close: Close,
  sort: Sort,
  'v-left': VLeft,
  'v-right': VRight,
  conversation: Conversation,
  campaigns: Campaigns,
  leads: Leads,
  conversations: Conversations,
  bot: Bot,
  tasks: Tasks,
  'arrow-down': ArrowDown,
  ArrowDownBlue,
  'arrow-up': ArrowUp,
  success: Success,
  lead: Lead,
  overflow: Overflow,
  search: Search,
  delete: Delete,
  tick: Tick,
  'csv-file-icon': CsvFileIcon,
  'error-line': ErrorLine,
  'trash-icon-new': TrashIconNew,
  ellipsis: Ellipsis,
  iconBot: IconBot,
  upload: Upload,
  paper: Paper,
  'close-bold': CloseBold,
  'alert-error': AlertError,
  'no-data': NoData,
  link: Link,
  notes: Notes,
  insights: Insights,
  integrations: Integrations,
  'no-tasks': NoTasks,
  info: Info,
  'broadcast-green': BroadCastGreen,
  'broadcast-red': BroadCastRed,
  'broadcast-grey': BroadCastGrey,
  'broadcast-orange': BroadcastOrange,
  'green-lock': GreenLock,
  'red-lock': RedLock,
  'delete-trash-icon': DeleteTrashIcon,
  'ai-icon-extended-blue': AiIconExtendedBlue,
  'introduction-email-icon': IntroductionEmailIcon,
  'email-default-icon': EmailDefaultIcon,
  'not-interested-email-icon': NotInterestedEmailIcon,
  'qualified-email-icon': QualifiedEmailIcon,
  'edit-email-icon': EditEmailIcon,
  'input-error': InputError,
  'cc-sales-rep-icon': CcSalesRepIcon,
  'lead-icon-yellow': LeadIconYellow,
  'reply-icon-yellow': ReplyIconYellow,
  'broadcast-blue': BroadcastBlue,
  'icon-lead': IconLead,
  'icon-ai-assistant': IconAiAssistant,
  'icon-hide': IconHide,
  envelope: Envelope,
  pencil: Pencil,
  'add-icon-grey': AddIconGrey,
  'message-close': MessageClose,
  user: User,
  'icon-zoom-out': IconZoomOut,
  'icon-zoom-in': IconZoomIn,
  'center-to-fit-icon': CenterToFitIcon,
  'no-invites': NoInvites,
  'integration-install-package': IntegrationInstallPackage,
  'integration-description': IntegrationDescription,
  'merge-tag-icon': MergeTagIcon,
  'search-icon-black': SearchIconBlack,
  'picture-icon': PictureIcon,
  'paper-clip-icon': PaperClipIcon,
  'icon-electric': IconElectric,
  'add-icon-white': AddIconWhite,
  'delete-small': DeleteSmall,
  'trash-bin': TrashBin,
  'sales-reps-list': SalesRepsList,
  'sales-group-icon': SalesGroupIcon,
  'calendar-link-icon': CalendarLinkIcon,
  'alert-info': AlertInfo,
  'alert-success': AlertSuccess,
  'alert-critical': AlertCritical,
  'alert-warning': AlertWarning,
  'chevron-right': ChevronRight,
  ChevronUp,
  ChevronDown,
  'chevron-right-light-blue': ChevronRightLightBlue,
  'edit-transparent': EditTransparent,
  'alert-error-red': AlertErrorRed,
  'create-topic-step-icon': CreateTopicStepIcon,
  'edit-button-icon': EditButtonIcon,
  'integration-salesforce': IntegrationSalesforce,
  'integration-hubspot': IntegrationHubspot,
  'schedule-campaign-step-icon': ScheduleCampaignStepIcon,
  'review-check-icon': ReviewCheckIcon,
  'review-ellipsis-icon': ReviewEllipsisIcon,
  'review-error-icon': ReviewErrorIcon,
  'icon-warning': IconWarning,
  'icon-tooltip-info': IconTooltipInfo,
  'rock-hand-icon': RockHandIcon,
  'coffee-mug-icon': CoffeeMugIcon,
  icon_pen: IconPen,
  'ai-inbox-step-icon': AiInboxStepIcon,
  'ai-set-working-hours-step-icon': AiSetWorkingHoursStepIcon,
  'ai-personalise-assistant-step-icon': AiPersonaliseAssistantStepIcon,
  'ai-bcc-preference-step-icon': AiBccPreferenceStepIcon,
  'conversation-flow-step': ConversationFlowStep,
  'lead-owner-step': LeadOwnerStep,
  'suppression-lists-step': SuppressionListsStep,
  'notification-icon': NotificationIcon,
  'default-bot-avatar': DefaultBotAvatar,
  'schedule-step': ScheduleStep,
  'icon-integration-salesforce': IconIntegrationSalesforce,
  video: Video,
  'icon-email': IconEmail,
  'icon-reply': IconReply,
  'icon-video': IconVideo,
  'ic-tick': IcTick,
  'ic-alert': IcAlert,
  'icon-gmail': IconGmail,
  'icon-outlook': IconOutlook,
  excel: Excel,
  connect: Connect,
  enrollment: Enrollment,
  warning: Warning,
  check: Check,
  'install-package': InstallPackage,
  triangle: Triangle,
  'arrow-forward': ArrowForward,
  lock: Lock,
  Edit,
  CsvFile,
  DocFile,
  FileFile,
  GifFile,
  JpgFile,
  KeyFile,
  MdbFile,
  NumFile,
  PageFile,
  PdfFile,
  PngFile,
  PptFile,
  SvgFile,
  TxtFile,
  XlsFile,
  ZipFile,
  archive: Archive,
  Stack,
  ArrowRight,
  Play,
  IconSchedule,
  ArrowSide,
  Chat,
  Rocket,
  ReturnRight,
  IconClouds,
  question: Question,
  AddressCard,
  Map,
  AiDefaultActionIcon,
  download: Download,
  Filter,
  Eye,
  ToastCritical,
  KeyLeft,
  KeyRight,
  Review,
  Refresh,
  EnrollLeadsStep,
  Flag,
  DotLoader,
  Zapier,
  AddIconDark,
  FolderOpenIcon,
  PieChartIcon,
  LinkDark,
  DynamicContent,
  SixSenseLogo,
  SixSenseLogoWhite,
  DragHandle,
  Pause,
  SwitchHorizontal,
  CustomView,
  Automation,
  AddMessage,
  Gear,
  Slash,
  SplitTestIcon,
  AddPerson,
  Bold,
  Italic,
  MergeTag,
  Redo,
  Undo,
  Strikethrough,
  Blockquote,
  OrderedList,
  UnorderedList,
  LinkIcon,
  Image,
  Heading,
  GearTransparent,
  ChatBot,
  Send,
  EditPencil,
  BriefLibrary,
  EnterKey,
  EscKey,
  RKey,
  FileAdd,
  BookOpen,
  Indent,
  Outdent,
  Underline,
  CheckDefault,
  LinkBroken,
  ReturnDownRight,
  Briefcase,
  ExpandText,
  MessageText,
  ShortenText,
  EmailDivider,
  StarClusterGradient,
  MailOff,
  Lightning,
  SampleContactBadge,
  ImageOutline,
  LightningGradient,
  DotLoaderBouncy,
};

export default icons;
