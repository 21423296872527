import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import snakecaseKeys from 'snakecase-keys';
import { getJSON, put, post } from '../../services/fetch';
import { stringifyToQueryString } from '../../utils/formatting';
import {
  TouchPointSchema,
  HumanTouchPointSchema,
  ManualReplyPayload,
  ManualReplyAPIPayload,
  DecoratedConsolidatedEmailSchema,
  ConsolidatedEmailSchema,
} from '../schemas';
import { EngagementSchema } from './../schemas/engagement';
import { consolidatedEmailDecorator, generateEngagementEmailsQueryKey } from './engagement';

interface TouchPointAPISchema {
  touchPoints: TouchPointSchema[];
}
interface TouchPointsQueryKeyPayload {
  conversationId?: string;
  stage?: 'scheduled';
}
export const generateTouchPointsQueryKey = ({
  conversationId,
  stage,
}: TouchPointsQueryKeyPayload) => {
  return ['touchpoints', { conversationId, stage }];
};
export function useGetScheduledTouchPoints({
  conversationId,
  emailsQueryFlag,
}: {
  conversationId?: string;
  emailsQueryFlag?: boolean;
}) {
  const q: TouchPointsQueryKeyPayload = {
    conversationId,
    stage: 'scheduled',
  };
  const queryKey = generateTouchPointsQueryKey(q);
  const query = useQuery(
    queryKey,
    async () => {
      const qs = stringifyToQueryString(q);
      const touchPoints = await getJSON(`/touch_points${qs}`).then((r: TouchPointAPISchema) =>
        r.touchPoints.map(t => touchPointDecorator(t))
      );
      return touchPoints;
    },
    {
      enabled: !emailsQueryFlag && !!conversationId,
    }
  );
  return { ...query, data: query.data || [] };
}

interface HumanTouchPointAPISchema {
  humanTouchPoints: HumanTouchPointSchema[];
}
interface HumanTouchPointsQueryKeyPayload {
  humanConversationId?: string;
  stage?: 'scheduled';
}
export function generateHumanTouchPointsQueryKey({
  humanConversationId,
  stage,
}: HumanTouchPointsQueryKeyPayload) {
  return ['human_touch_points', { humanConversationId, stage }];
}
export function useGetScheduledHumanTouchPoints({
  humanConversationId,
  emailsQueryFlag,
}: {
  emailsQueryFlag?: boolean;
  humanConversationId?: string;
}) {
  const q: HumanTouchPointsQueryKeyPayload = { humanConversationId, stage: 'scheduled' };
  const queryKey = generateHumanTouchPointsQueryKey(q);
  const query = useQuery(
    queryKey,
    () => {
      const qs = stringifyToQueryString(q);
      return getJSON(`/human_touch_points${qs}`).then((r: HumanTouchPointAPISchema) =>
        r.humanTouchPoints.map(t => humanTouchPointDecorator(t))
      );
    },
    {
      enabled: !emailsQueryFlag && !!humanConversationId,
    }
  );
  return { ...query, data: query.data || [] };
}
export function useSendTouchPointNow() {
  const queryClient = useQueryClient();
  return useMutation(
    async (touchPoint: TouchPointSchema | DecoratedConsolidatedEmailSchema) => {
      let updatedTouchPoint = cloneDeep(touchPoint);
      updatedTouchPoint.scheduledFor = new Date().toISOString();
      const t = await put(`/touch_points/${touchPoint.id}`, { touchPoint: updatedTouchPoint });
      return touchPointDecorator(t.touchPoint);
    },
    {
      onSuccess: updatedTouchPoint => {
        const queryKey = generateTouchPointsQueryKey({
          stage: 'scheduled',
          conversationId: updatedTouchPoint.conversationId.toString(),
        });
        queryClient.setQueryData<TouchPointSchema[]>(queryKey, touchPoints =>
          touchPoints
            ? touchPoints.map(t => (t.id === updatedTouchPoint.id ? updatedTouchPoint : t))
            : touchPoints
        );
      },
    }
  );
}
export function useSendHumanTouchPointNow() {
  const queryClient = useQueryClient();
  return useMutation(
    async (humanTouchPoint: HumanTouchPointSchema) => {
      let updatedHumanTouchPoint = cloneDeep(humanTouchPoint);
      updatedHumanTouchPoint.scheduledFor = new Date().toISOString();
      const t = await put(`/human_touch_points/${humanTouchPoint.id}`, {
        humanTouchPoint: updatedHumanTouchPoint,
      });
      return humanTouchPointDecorator(t.humanTouchPoint);
    },
    {
      onSuccess: updatedHumanTouchPoint => {
        const queryKey = generateHumanTouchPointsQueryKey({
          humanConversationId: updatedHumanTouchPoint.humanConversationId.toString(),
          stage: 'scheduled',
        });
        queryClient.setQueryData<HumanTouchPointSchema[]>(queryKey, humanTouchPoints =>
          humanTouchPoints
            ? humanTouchPoints.map(t =>
                t.id === updatedHumanTouchPoint.id ? updatedHumanTouchPoint : t
              )
            : humanTouchPoints
        );
      },
    }
  );
}
export function useSendScheduledEmailNow() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ engagementId, emailId }: { emailId: string; engagementId: string }) => {
      const url = `/engagements/${engagementId}/emails/${emailId}/send_now`;
      const { data }: { data: ConsolidatedEmailSchema } = await post(url);
      const updatedEmail = consolidatedEmailDecorator(data);
      return { updatedEmail, engagementId, emailId };
    },
    {
      onSuccess: ({ updatedEmail, engagementId, emailId }) => {
        const queryKey = generateEngagementEmailsQueryKey(engagementId);
        queryClient.setQueryData<DecoratedConsolidatedEmailSchema[]>(queryKey, emails =>
          emails ? emails.map(e => (e.id === emailId ? updatedEmail : e)) : []
        );
      },
    }
  );
}
export function useSendManualEmail() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      conversationId,
      engagementId,
      reply,
    }: {
      conversationId: string;
      engagementId: string;
      reply: ManualReplyPayload;
    }) => {
      const {
        immediate,
        tos,
        ccs,
        email: { body, attachments },
      } = reply;
      const payload: ManualReplyAPIPayload = snakecaseKeys({
        data: {
          type: 'emails',
          attributes: {
            tos,
            ccs,
            immediate,
            email: {
              body,
              attachmentIds: attachments.map(a => a.id.toString()),
            },
          },
        },
      });
      const url = `/engagements/${engagementId}/emails`;
      await post(url, payload);
      return { conversationId, engagementId };
    },
    {
      onSuccess: ({ conversationId, engagementId }) => {
        const engagementEmailsQueryKey = generateEngagementEmailsQueryKey(engagementId);
        const scheduledTouchPointsQueryKey = generateTouchPointsQueryKey({
          conversationId,
          stage: 'scheduled',
        });
        queryClient.invalidateQueries(engagementEmailsQueryKey);
        queryClient.invalidateQueries(scheduledTouchPointsQueryKey);
      },
    }
  );
}

const touchPointDecorator = (t: TouchPointSchema) => {
  t.type = 'touchPoint';
  t.isSending = t.status === 'sending';
  return t;
};
const humanTouchPointDecorator = (t: HumanTouchPointSchema) => {
  t.type = 'humanTouchPoint';
  t.isSending = t.state === 'sending';
  return t;
};

export async function dispute(payload: {
  comment: string;
  engagement: EngagementSchema;
  sentEmailId: number | string;
}) {
  const r = post('/customer_disputes', payload);
  return r;
}
