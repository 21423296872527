import {
  InfoCircle,
  CheckCircle,
  AlertTriangle,
  AlertCircle,
  ArrowNarrowRight,
  XClose,
} from '@saleswhale/barnacle/icons';
import { ReactElement, ReactNode, useState, CSSProperties } from 'react';
import styles from './AlertMessage.module.scss';

interface Props {
  title: string;
  actionText?: string;
  children?: ReactNode;
  className?: string;
  isRemovable?: boolean;
  noIcon?: boolean;
  onActionClick?: () => void;
  onRemove?: () => void;
  style?: CSSProperties;
  testId?: string;
  type?: 'success' | 'error' | 'info' | 'warning';
}
// TODO: we should consider setting a max width for this
export function AlertMessage({
  noIcon,
  actionText,
  onActionClick,
  title,
  children,
  isRemovable,
  style,
  type = 'info',
  className = '',
  testId = 'AlertMessage',
  onRemove,
}: Props) {
  const [isVisible, setIsVisible] = useState(true);
  const icons: { [key: string]: ReactElement } = {
    info: <InfoCircle size={16} />,
    success: <CheckCircle size={16} />,
    error: <AlertCircle size={16} />,
    warning: <AlertTriangle size={16} />,
  };
  const icon = icons[type];
  const containerClassName = type.charAt(0).toUpperCase() + type.slice(1);

  if (!isVisible) return null;
  return (
    <div
      className={`${styles[containerClassName]} ${className}`}
      data-testid={testId}
      style={style}
    >
      <div className={styles.ContentContainer}>
        {!noIcon && (
          <div className={styles.Icon} data-testid={`${testId}__icon`}>
            {icon}
          </div>
        )}
        {title && (
          <div className={styles.TitleContainer} data-testid={`${testId}__title`}>
            <span>{title}</span>
          </div>
        )}
        {children ? (
          <div className={styles.Description} data-testid={`${testId}__description`}>
            <span>{children}</span>
          </div>
        ) : null}
        {actionText ? (
          <div
            className={styles.ActionContainer}
            data-testid={`${testId}__action`}
            onClick={onActionClick}
          >
            <div className={styles.ActionText} data-testid={`${testId}__actionText`}>
              {actionText}
            </div>
            <ArrowNarrowRight size={16} />
          </div>
        ) : null}
        {isRemovable && (
          <div className={styles.CloseContainer}>
            <XClose
              className={styles.CloseIcon}
              data-testid={`${testId}__remove`}
              onClick={() => {
                onRemove && onRemove();
                setIsVisible(false);
              }}
              size={16}
            />
          </div>
        )}
      </div>
    </div>
  );
}
