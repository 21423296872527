import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  EventLogsApiIndexSchema,
  EventLogsApiSchema,
  EventLogsIndexPayload,
  EventLogsSchema,
} from 'app/schemas/engagementStatus';
import { PER_PAGE, emptyMeta } from 'config/constants';
import { cloneDeep, lowerCase, upperFirst } from 'lodash';
import { getJSON, post } from '../../services/fetch';
import {
  formattedDisplayName,
  generateTerminalStatusFromClassificationValue,
  snakecaseKeys,
  stringifyToQueryString,
} from '../../utils/formatting';
import { NegativeToast, PositiveToast, didError } from '../components/Elements';
import {
  EngagementSchema,
  EmailSchema,
  EngagementAPISchema,
  MetaSchema,
  EngagementsMetricsSchema,
  DecoratedEmailSchema,
  EngagementIndexAPISchema,
  EngagementIndexSchema,
  EmailAPISchema,
  ConsolidatedEmailSchema,
  DecoratedConsolidatedEmailSchema,
} from '../schemas';

const ENDPOINT = `/engagements`;
export const generateConversationEmailsQueryKey = (conversationId?: string) =>
  conversationId ? ['conversations', conversationId, 'emails'] : [];
export const generateEngagementEmailsQueryKey = (engagementId?: string) =>
  engagementId ? ['engagements', engagementId, 'emails'] : [];
export const generateConversationStatusUpdateEventLogsQueryKey = ({
  id,
  page,
  perPage,
}: EventLogsIndexPayload) => ['conversations_status', id, 'updates', { page, perPage }];

export function useGetEngagement(id: string) {
  return useQuery(['engagements', id.toString()], () =>
    getJSON(`${ENDPOINT}/${id}`).then(r => EngagementDecorator(r.engagement))
  );
}

export const loadUpdatedEngagement = async (
  id: string | number
): Promise<EngagementIndexSchema> => {
  const r: { engagement: EngagementIndexAPISchema } = await getJSON(`${ENDPOINT}/${id}`);
  const engagement = await EngagementIndexDecorator(r.engagement);
  return engagement;
};
export function useGetEngagements({
  filters,
  q,
  accountId,
  isRefetching,
}: {
  accountId?: string;
  filters?: string | null;
  isRefetching?: boolean;
  q?: string | null;
} = {}) {
  return useInfiniteQuery(
    ['engagements', { filters, q, accountId }],
    async ({ pageParam: page = 1 }) => {
      const qs = stringifyToQueryString(
        { page, filters, accountId, q: q || null },
        {
          skipNulls: true,
        }
      );
      const r = await getJSON(`${ENDPOINT}${qs}`);
      const engagements: EngagementIndexSchema[] = r.engagements.map(EngagementIndexDecorator);
      return { meta: r.meta as MetaSchema, data: engagements };
    },
    {
      getNextPageParam: ({ meta }) => meta.nextPage || undefined,
      refetchInterval: engagements => (engagements && isRefetching ? 5000 : false),
    }
  );
}

export const consolidatedEmailDecorator = (
  e: ConsolidatedEmailSchema
): DecoratedConsolidatedEmailSchema => {
  const senderName = e.attributes.sender ? e.attributes.sender.split('<')[0].trim() : '';
  return { ...e.attributes, id: e.id, senderName };
};

export function useGetConsolidatedEmails({
  engagementId,
  emailsQueryFlag,
}: {
  emailsQueryFlag?: boolean;
  engagementId?: string;
}) {
  const queryKey = generateEngagementEmailsQueryKey(engagementId);
  const query = useQuery(
    queryKey,
    async () => {
      const { data }: { data: ConsolidatedEmailSchema[] } = await getJSON(
        `${ENDPOINT}/${engagementId}/emails`
      );
      return data.map(e => consolidatedEmailDecorator(e));
    },
    { enabled: emailsQueryFlag && !!engagementId }
  );

  return { ...query, data: query.data || [] };
}

export function useGetEmails({
  conversationId,
  emailsQueryFlag,
}: {
  conversationId?: string;
  emailsQueryFlag?: boolean;
}) {
  const queryKey = generateConversationEmailsQueryKey(conversationId);
  const query = useQuery(
    queryKey,
    () =>
      getJSON(`/conversations/${conversationId}/emails`).then((r: EmailAPISchema) => {
        return r.emails.map(e => emailDecorator(e)).reverse();
      }),
    {
      enabled: !emailsQueryFlag && !!conversationId,
    }
  );
  return { ...query, data: query.data || [] };
}

const tempDecodeBase64Id = (id: string | number): string => {
  if (typeof id === 'number') return id.toString();
  // we want to check if the string is actually encoded.
  // else, the method could throw an error if it's not a valid base64 encoded string
  // this is a temporary measure while we work with the older endpoint, where the id is an encoded string.
  const isEncoded = window.btoa(window.atob(id)) === id;
  return isEncoded ? window.atob(id.toString()).split('/')[1] : id;
};

export const emailDecorator = (e: EmailSchema): DecoratedEmailSchema => {
  const r = {
    ...e,
    senderEmail: e.sender.match(/<(.*)>/)?.pop() ?? null,
    senderName: e.sender.split('<')[0].trim(),
    id: tempDecodeBase64Id(e.id),
  };

  return r;
};

interface PauseAndResumePayload {
  encodedFilters?: string;
  engagementIds?: (number | string)[];
  isAll?: boolean;
}
const formatPauseAndResumePayload = (
  { engagementIds = [], encodedFilters, isAll = false }: PauseAndResumePayload,
  resource: 'pauses' | 'resumes'
) => {
  const type = (() => {
    if (isAll) {
      if (encodedFilters) {
        return 'filters';
      }
      return 'all_engagements';
    }
    if (engagementIds.length > 0) {
      return 'engagements_list';
    }
    console.error('Something bad has happened here');
    return '';
  })();
  const engagements =
    type === 'engagements_list'
      ? {
          data: engagementIds.map(id => ({ type: 'engagements', id })),
        }
      : null;
  const filters = type === 'filters' ? encodedFilters : null;
  const relationships = engagements
    ? {
        engagements,
      }
    : null;
  return {
    data: {
      type: `engagement_${resource}`,
      attributes: {
        type,
        ...(filters && { filters }),
      },
      ...(relationships && { relationships }),
    },
  };
};
export function usePauseEngagements() {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: PauseAndResumePayload) => {
      const p = formatPauseAndResumePayload(payload, 'pauses');
      const res = await post(`/engagement_pauses`, snakecaseKeys(p, { deep: true }));
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['engagements']);
        PositiveToast('Conversations paused!');
      },
    }
  );
}
export function useResumeEngagements() {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: PauseAndResumePayload) => {
      const p = formatPauseAndResumePayload(payload, 'resumes');
      const res = await post(`/engagement_resumes`, snakecaseKeys(p, { deep: true }));
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['engagements']);
        PositiveToast('Conversations resumed!');
      },
    }
  );
}
export function usePauseEngagement() {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const p = formatPauseAndResumePayload({ engagementIds: [id] }, 'pauses');
      const res = await post(`/engagement_pauses`, snakecaseKeys(p, { deep: true }));
      return res;
    },
    {
      onSuccess: (_, id) => {
        queryClient.setQueryData<EngagementSchema>(['engagements', id], old =>
          old ? { ...old, isPaused: true, isResumable: true, isPausable: false } : old
        );
        PositiveToast('Conversation paused.');
      },
    }
  );
}
export function useResumeEngagement() {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const p = formatPauseAndResumePayload({ engagementIds: [id] }, 'resumes');
      const res = await post(`/engagement_resumes`, snakecaseKeys(p, { deep: true }));
      return res;
    },
    {
      onSuccess: (_, id) => {
        queryClient.setQueryData<EngagementSchema>(['engagements', id], old =>
          old ? { ...old, isPaused: false, isResumable: false, isPausable: true } : old
        );
        queryClient.invalidateQueries(['engagements']);
        PositiveToast('Conversation resumed.');
      },
    }
  );
}
export const cancelEngagement = async (id: number | string) => {
  const r = await post(`${ENDPOINT}/${id}/cancel`);
  const engagement = await EngagementDecorator(r.engagement);
  return engagement;
};
export function useCancelEngagement() {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => post(`${ENDPOINT}/${id}/cancel`).then(r => EngagementDecorator(r.engagement)),
    {
      onSuccess: engagement => {
        queryClient.setQueryData(['engagements', engagement.id.toString()], engagement);
        PositiveToast('Successfully canceled conversation.');
      },
    }
  );
}
export const useCancelEngagementFromIndex = () => {
  return useMutation(
    async (id: number | string) => {
      const r = await post(`${ENDPOINT}/${id}/cancel`);
      const engagement = EngagementIndexDecorator(r.engagement);
      return engagement;
    },
    {
      onSuccess: () => PositiveToast('Successfully canceled conversation.'),
    }
  );
};

export function generateEngagementFormattedResult({
  result,
  status,
}: {
  result: string;
  status: string;
}) {
  return result
    ? result === 'referral_canceled'
      ? 'Canceled'
      : upperFirst(lowerCase(result))
    : status === 'pending_ai_response'
    ? 'Pending AI response'
    : upperFirst(lowerCase(status));
}

export const EngagementIndexDecorator = (
  engagement: EngagementIndexAPISchema
): EngagementIndexSchema => {
  // TODO: work with BE to return pending_contact_response and pending_ai_response after breakthrough. This decision in caution to ensure nothing breaks in the BE side but this cancer gotta go right after
  const { actions, result } = engagement;
  const status =
    engagement.status === 'pending_lead_response'
      ? 'pending_contact_response'
      : engagement.status === 'pending_bot_response'
      ? 'pending_ai_response'
      : engagement.status;
  const hasHumanReviewActions =
    actions.humanReviewAssign ||
    actions.humanReviewComplete ||
    actions.humanReviewDiscard ||
    actions.humanReviewReferralCancel ||
    actions.humanReviewReplyAsBot ||
    actions.humanReviewUpdate;
  const isOngoing =
    status === 'pending_contact_response' ||
    status === 'pending_ai_response' ||
    status === 'human_review';
  const formattedResult = generateEngagementFormattedResult({ result, status });

  const e: EngagementIndexSchema = {
    ...engagement,
    status,
    hasHumanReviewActions,
    isOngoing,
    isInHumanReview: result === 'human_review',
    isFinishedNegative: !(result === 'qualified' || result === 'human_review'),
    isFinishedPositive: result === 'qualified',
    formattedResult,
  };
  return e;
};

export const EngagementDecorator = (engagement: EngagementAPISchema): EngagementSchema => {
  // TODO: work with BE to return pending_contact_response and pending_ai_response after breakthrough. This decision in caution to ensure nothing breaks in the BE side but this cancer gotta go right after
  const { result, actions, engagementMemberships, emailCount } = engagement;
  const status =
    engagement.status === 'pending_lead_response'
      ? 'pending_contact_response'
      : engagement.status === 'pending_bot_response'
      ? 'pending_ai_response'
      : engagement.status;
  const isOngoing =
    status === 'pending_contact_response' ||
    status === 'pending_ai_response' ||
    status === 'human_review';
  const hasHumanReviewActions =
    actions.humanReviewAssign ||
    actions.humanReviewComplete ||
    actions.humanReviewDiscard ||
    actions.humanReviewReferralCancel ||
    actions.humanReviewReplyAsBot ||
    actions.humanReviewUpdate;
  const clonedLastEmail = cloneDeep(engagement.lastEmail);
  const formattedLastEmail = clonedLastEmail
    ? { ...clonedLastEmail, id: tempDecodeBase64Id(clonedLastEmail.id) }
    : clonedLastEmail;

  const formattedResult = generateEngagementFormattedResult({ result, status });

  const e: EngagementSchema = {
    ...engagement,
    status,
    hasHumanReviewActions,
    hasEmails: emailCount > 0,
    optedOutLeads: engagementMemberships.filter(e => e.optOut).map(e => e.lead),
    otherLeads: engagementMemberships.filter(e => !(e.optOut || e.main)).map(e => e.lead),
    mainLead: engagementMemberships.filter(e => e.main).map(e => e.lead)[0], // TODO: see if there are other parts that use the old (incomplete) mainLead attribute from the payload
    isOngoing,
    isInHumanReview: result === 'human_review',
    isFinishedNegative: !(result === 'qualified' || result === 'human_review'),
    isFinishedPositive: result === 'qualified',
    formattedResult,
    lastEmail: formattedLastEmail,
  };
  return e;
};

export interface EngagementsExportPayload {
  type: string;
  engagementIds?: (string | number)[];
  filters?: string;
}
export function useExportEngagements() {
  return useMutation((payload: EngagementsExportPayload) => post(`${ENDPOINT}/export`, payload), {
    onSuccess: () =>
      PositiveToast(
        'Successfully exported the conversations. You will receive an email shortly. Please check.'
      ),
    onError: () =>
      NegativeToast(
        'Something went wrong while exporting. If the problem persists, contact us at support@6sense.com.'
      ),
  });
}
export function useGetEngagementMetrics() {
  return useQuery(['engagements', 'metrics'], () =>
    getJSON(`${ENDPOINT}/metrics`).then(r => r as EngagementsMetricsSchema)
  );
}
export function useUpdateEngagementStatus() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      id,
      engagementId,
      result,
      resultValue,
    }: {
      engagementId: string;
      id: string;
      result:
        | 'qualified_positive_intent_(goal_conversion)'
        | 'negative_intent'
        | 'not_interested_now';
      resultValue: string;
    }) => {
      const payload = {
        data: {
          attributes: {
            result,
          },
        },
      };
      const response = await post(`/emails/${id}/email_classifications`, payload);
      return { ...response, engagementId, resultValue };
    },
    {
      onMutate: ({ engagementId, resultValue }) => {
        const queryKey = ['engagements', engagementId];
        const formattedResultValue = generateEngagementFormattedResult({
          status: resultValue,
          result: resultValue,
        });
        const oldEngagement = queryClient.getQueryData(queryKey);
        queryClient.setQueryData<EngagementSchema>(queryKey, old =>
          old
            ? {
                ...old,
                result: resultValue,
                status: resultValue,
                formattedResult: formattedResultValue,
                isFinishedNegative: !(resultValue === 'qualified'),
                isFinishedPositive: resultValue === 'qualified',
              }
            : old
        );
        return function rollback() {
          queryClient.setQueryData(queryKey, oldEngagement);
        };
      },
      onSuccess: () => {
        PositiveToast('Conversation status updated!');
      },
      onError: (e, _, rollback) => {
        rollback && rollback();
        didError(e);
      },
    }
  );
}

function eventLogsDecorator(r: EventLogsApiSchema) {
  const {
    attributes: {
      result,
      createdAt,
      createdBy: { firstName, lastName },
    },
    id,
  } = r;
  return {
    createdAt,
    createdBy: formattedDisplayName(firstName, lastName),
    id,
    result: generateTerminalStatusFromClassificationValue(result),
  };
}
export function useGetUpdateEngagementStatusEventLogs(
  { id, page = 1, perPage = PER_PAGE }: EventLogsIndexPayload,
  enabled = true
) {
  const qs = stringifyToQueryString({ page, perPage });
  const query = useQuery(
    generateConversationStatusUpdateEventLogsQueryKey({ id, page, perPage }),
    async () => {
      const { data, meta }: EventLogsApiIndexSchema = await getJSON(
        `/emails/${id}/email_classifications${qs}`
      );
      const eventLogs: EventLogsSchema[] = data.map((d: EventLogsApiSchema) =>
        eventLogsDecorator(d)
      );
      return { eventLogs, meta };
    },
    {
      enabled: enabled && !!id,
    }
  );
  return { ...query, data: query.data || { eventLogs: [], meta: emptyMeta } };
}
